<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')" class="cur">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>

    <div class="main">
      <el-carousel height="600px" class="carouselClass">
        <el-carousel-item v-for="item in imgListItem" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="text">
        <div>{{ textTitle }}</div>
        <div>项目详情</div>
        <div class="textClass"></div>
        <div>
          <div>地址：{{ address }}</div>
          <div>面积：{{ area }}</div>
        </div>
        <div>施工完成时间</div>
        <div class="textClass"></div>
        <div>{{ time }}</div>
        <div>项目背景</div>
        <div class="textClass"></div>
        <div>
          {{ text }}
        </div>
        <div class="back" @click="back">
          <img src="../../public/imgs/caseItem/back.png" alt="" />
          <span>返回列表</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import case1_1 from "../../public/imgs/caseItem/case1_1.jpg";
import case1_2 from "../../public/imgs/caseItem/case1_2.jpg";
import case1_3 from "../../public/imgs/caseItem/case1_3.jpg";
import case1_4 from "../../public/imgs/caseItem/case1_4.jpg";
import case1_5 from "../../public/imgs/caseItem/case1_5.jpg";
import case1_6 from "../../public/imgs/caseItem/case1_6.jpg";
import case1_7 from "../../public/imgs/caseItem/case1_7.jpg";
import case2_1 from "../../public/imgs/caseItem/case2_1.jpg";
import case2_2 from "../../public/imgs/caseItem/case2_2.jpg";
import case2_3 from "../../public/imgs/caseItem/case2_3.jpg";
import case2_4 from "../../public/imgs/caseItem/case2_4.jpg";
import case2_5 from "../../public/imgs/caseItem/case2_5.jpg";
import case3_1 from "../../public/imgs/caseItem/case3_1.jpg";
import case3_2 from "../../public/imgs/caseItem/case3_2.jpg";
import case3_3 from "../../public/imgs/caseItem/case3_3.jpg";
import case3_4 from "../../public/imgs/caseItem/case3_4.jpg";
import case3_5 from "../../public/imgs/caseItem/case3_5.jpg";
import case3_6 from "../../public/imgs/caseItem/case3_6.jpg";
import case3_7 from "../../public/imgs/caseItem/case3_7.jpg";
import case3_8 from "../../public/imgs/caseItem/case3_8.jpg";
import case4_1 from "../../public/imgs/caseItem/case4_1.png";
import case4_2 from "../../public/imgs/caseItem/case4_2.png";
import case4_3 from "../../public/imgs/caseItem/case4_3.png";
import case4_4 from "../../public/imgs/caseItem/case4_4.png";
// import case4_5 from "../../public/imgs/caseItem/case4_5.png";
import case4_6 from "../../public/imgs/caseItem/case4_6.png";
import case4_7 from "../../public/imgs/caseItem/case4_7.png";
import case4_8 from "../../public/imgs/caseItem/case4_8.png";
import case5_1 from "../../public/imgs/caseItem/case5_1.jpg";
import case5_2 from "../../public/imgs/caseItem/case5_2.jpg";
import case5_3 from "../../public/imgs/caseItem/case5_3.jpg";
import case5_4 from "../../public/imgs/caseItem/case5_4.jpg";
import case6_1 from "../../public/imgs/caseItem/case6_1.jpg";
import case6_2 from "../../public/imgs/caseItem/case6_2.jpg";
import case7_1 from "../../public/imgs/caseItem/case7_1.jpg";
import case7_2 from "../../public/imgs/caseItem/case7_2.jpg";
import case7_3 from "../../public/imgs/caseItem/case7_3.jpg";
import case7_4 from "../../public/imgs/caseItem/case7_4.jpg";
import case7_5 from "../../public/imgs/caseItem/case7_5.jpg";
import case8_1 from "../../public/imgs/caseItem/case8_1.jpg";
import case8_2 from "../../public/imgs/caseItem/case8_2.jpg";
import case9_1 from "../../public/imgs/caseItem/case9_1.png";
import case9_2 from "../../public/imgs/caseItem/case9_2.jpg";
import case9_3 from "../../public/imgs/caseItem/case9_3.jpg";
import case10_1 from "../../public/imgs/caseItem/case10_1.png";
import case10_2 from "../../public/imgs/caseItem/case10_2.png";
import case10_3 from "../../public/imgs/caseItem/case10_3.png";
import case10_4 from "../../public/imgs/caseItem/case10_4.png";
import case13_1 from "../../public/imgs/caseItem/case13_1.png";
import case14_1 from "../../public/imgs/caseItem/case14_1.png";
import case14_2 from "../../public/imgs/caseItem/case14_2.png";
import case14_3 from "../../public/imgs/caseItem/case14_3.png";
import case14_4 from "../../public/imgs/caseItem/case14_4.png";
import case15_1 from "../../public/imgs/caseItem/case15_1.png";
import case15_2 from "../../public/imgs/caseItem/case15_2.png";
import case15_3 from "../../public/imgs/caseItem/case15_3.png";
import case15_4 from "../../public/imgs/caseItem/case15_4.png";

export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {
      workList: [
        "金融办公",
        "别墅住宅",
        "商业综合体",
        "医疗教育",
        "休闲运动",
        "展览展会",
      ],
      imgListItem: [],
      textTitle: "",
      text: "",
      time: "",
      address: "",
      area: "",
    };
  },
  mounted() {
    if (this.$route.query.id == 1) {
      this.imgListItem = [
        case1_1,
        case1_2,
        case1_3,
        case1_4,
        case1_5,
        case1_6,
        case1_7,
      ];
      this.textTitle = "宝鸡汉强幼儿园";
      this.address = "陕西省宝鸡市";
    }
    if (this.$route.query.id == 2) {
      this.imgListItem = [case2_1, case2_2, case2_3, case2_4, case2_5];
      this.textTitle = "雍顺兴·臭鳜鱼·秦徽小筑";
      this.text =
        "徽菜的饮食文化与徽派建筑和人文分不开，雍顺兴作为徽菜知名品牌更是巧妙地将徽州文化植入就餐环境，打造人文与商业的完美结合。徽派建筑又称徽州建筑徽派，建筑作为徽文化的重要组成部分，历来为中外建筑大师所推崇。徽派建筑讲究规格礼数，集徽州山川风景之灵气，融中国风俗文化之精华，风格独特，结构严谨，雕镂精湛，不论是规划构思，还是平面及空间处理、建筑雕刻艺术的综合运用都充分体现了鲜明的地方特色。雍顺兴以古朴的白灰色背景墙去协调深沉安静的木质桌椅，自然和谐，天然去雕饰的纯朴之风弥漫了每个角落；景观墙采用徽派历史悠久的砖雕艺术装点，营造出徽派文化独有的雅致氛围；徽见臭桂鱼品牌店面成立以来，秉承口味品质精雕细琢，服务至上的大众化消费水平，在西安地区形成稳定的客户群里，被广大消费者所认可。";
      this.address = "西安市高新区89号枫林绿洲云顶园7号楼";
      this.area = "432㎡";
    }
    if (this.$route.query.id == 3) {
      this.imgListItem = [
        case3_1,
        case3_2,
        case3_3,
        case3_4,
        case3_5,
        case3_6,
        case3_7,
        case3_8,
      ];
      this.textTitle = "东方国际广场";
      this.text =
        "“东方亿象城项目”是由陕西蓉达投资发展有限公司投资在建的商业贸易中心;项目位于西安康复路商圈长乐西路中段，东面与西北商贸为邻，西与华东服饰广场为邻，南至永乐路，占地71.732亩。计划总投资约27亿人民币。是西安市批发市场商圈中集批发、休闲、娱乐、餐饮、展示、营销、品牌孵化、电子商务、酒店、办公等功能为一体的创新综合型商贸物流的现代化商业贸易中心。“东方亿象城”--西部地区最大的新财富中心。";
      this.address = "西安康复路商圈长乐西路中段";
      this.area = "18000㎡";
      this.time = "2018";
    }
    if (this.$route.query.id == 4) {
      this.imgListItem = [
        case4_1,
        case4_2,
        case4_3,
        case4_4,
        case4_6,
        case4_7,
        case4_8,
      ];
      this.textTitle = "广发银行榆林分行营业办公场所";
      this.text =
        "广发银行是经国务院、中国人民银行批准成立的首批股份制商业银行之一，经过三十余年的发展，已经成为国内主流商业银行。广发银行榆林分行（筹）是西安分行在陕西省内设立的首家二级分行，于2021年上半年开业。二十多年来，广发银行上下求索，奋发图强，由一家区域性银行成长为具有一定竞争优势和影响力的全国性股份制商业银行，业务拓展到了全国各主要区域和澳门地区。截至2011年末，广发银行已在北京、天津、辽宁、黑龙江、上海、江苏、浙江、河南、湖北、湖南、广东、云南等境内12个省（市）53个地级及地级以上城市和澳门特别行政区设立了30家分行、564家分支机构，743家自助银行，3,890台自助设备，在北京和香港设有代表处，在总行设有博士后科研工作站。";
      this.address =
        "榆林市高新技术产业园区沙河路152号盛高时代C座商业楼一层S1-2号、二层S2-2号房";
      this.area = "1805㎡";
      this.time = "2021";
    }
    if (this.$route.query.id == 5) {
      this.imgListItem = [case5_1, case5_2, case5_3, case5_4];
      this.textTitle = "兴业银行西安分行";
      this.text =
        "兴业银行西安分行成立于2004年10月，作为总行下属省级分行，在各级地方政府、监管部门及总行的领导和关怀下，秉承“一流银行，百年兴业”的企业愿景，准确把握国家宏观经济政策，积极投身陕西地方经济建设，实现了效益、质量、规模协调发展。西安分行凭借诚信经营、一流服务，赢得了社会各界的广泛赞誉，先后荣获“中国银行业文明规范服务示范单位”、“西安市工业提速增效工作先进单位”、“诚信纳税人”等荣誉称号，由于西安分行支持地方经济建设贡献突出，近三年连续被陕西省政府通报表扬，企业品牌形象不断提升。";
      this.address = "西安市雁塔区唐延路1号";
      this.area = "2000㎡";
      this.time = "2021";
    }
    if (this.$route.query.id == 6) {
      this.imgListItem = [case6_1, case6_2];
      this.textTitle = "兴业银行汉中分行装修工程";
      this.text =
        "兴业银行汉中分行作为西安分行下辖的第五家陕西境内异地分支机构，更是汉中当地首家全国性股份制商业银行，兴业银行汉中分行将秉持着“真诚服务，相伴成长”的经营理念和“科学、可持续”的发展理念，积极践行企业社会责任，践行金融服务实体经济的大行担当，造福汉中一方百姓，支持地方经济发展。着力于为汉中金融业市场提供各类金融服务，兴业银行汉中分行将不断完善网点服务工作，持续提升多元化金融服务能力，致力于为广大客户提供更加便捷、舒适、优质、高效的金融服务，与汉中经济相伴成长、共同兴业。";
      this.address = "陕西省汉中市汉台区西环路中段华汉新世纪商城";
      this.area = "2000㎡";
      this.time = "2019";
    }
    if (this.$route.query.id == 7) {
      this.imgListItem = [case7_1, case7_2, case7_3, case7_4, case7_5];

      this.textTitle = "兴业银行榆林分行装修装饰工程";
      this.text =
        "兴业银行榆林分行为西安分行下设异地二级分行，成立于2010年6月17日，始终坚持与客户“同发展、共成长”、“服务源自真诚”的经营理念，致力于为客户提供全面、优质、高效的金融服务，实现了稳健发展。";
      this.address = "陕西榆林市经济开发区明珠大道世纪精华大厦2层";
      this.area = "2500㎡";
      this.time = "2014";
    }
    if (this.$route.query.id == 8) {
      this.imgListItem = [case8_1, case8_2];
      this.textTitle = "明德门足浴店";
      this.text = "";
      this.address = "西安市明德门";
      this.area = "1250㎡";
      this.time = "2021";
    }
    if (this.$route.query.id == 9) {
      this.imgListItem = [case9_1, case9_2, case9_3];
      this.textTitle =
        "盛世长安三期24 #、25#、 30 #、33#、34#、35 #楼栋整体门窗安装工程";
      this.text =
        "盛世长安超前规划卓越设计，6大品质全面提升人居概念：低密度、高绿化率，花园式坡地中央水景园林，依地形自然分布，真正人车分流，独特林荫漫步道，高尚会所、风情商业街。盛世长安建筑外观现代活泼，高低呼应、疏密有秩，高层，小高层，商业裙房等坐落有致，高档社区会所集娱乐休闲、商业服务、商务休闲、健康运动、社区服务等为一体；社区步行商业街汇聚了服饰、茶楼、咖啡馆、糕点屋、花艺店等优雅高端生活所需要；更有幼儿园、社区超大地下停车场等配套，满足一切日常所需。";
      this.address = "西安市长安区郭杜盛世长安";
      this.area = "15000㎡";
      this.time = "2017";
    }
    if (this.$route.query.id == 10) {
      this.imgListItem = [case10_1, case10_2, case10_3, case10_4];
      this.textTitle = "i 尚高新·七星城";
      this.text =
        "位于西安市雁塔区，距离 2016 年 10 月 8 日正式开通运营的地铁 3 号线富裕路站 100 米，距西三环上下匝道 1 公里，东临西安外事学院北校区，西邻皂河景观公园，南邻富裕路，北邻规划路，门口即是公交站，出行十分便利。该项目以住宅和商务楼为主体，商业带动地产，地产促进商业繁荣。整个社区为一个完整、完善的有机体，用更少的资源消耗去满足既定人群的生活需求。";
      this.address = "西安市雁塔区";
      this.area = "188587.35㎡";
      this.time = "2016";
    }
    if (this.$route.query.id == 13) {
      this.imgListItem = [case13_1];
      this.textTitle = "招商银行锦业二路自助银行";
      this.text ="";
      this.address = "西安市长安区锦业二路法士特赛斐广场";
      this.area = "--";
      this.time = "2019";
    }
    if (this.$route.query.id == 14) {
      this.imgListItem = [case14_1, case14_2, case14_3, case14_4];
      this.textTitle = "建设银行（西影路东段支行）";
      this.text ="";
      this.address = "西安市西影路105号水木兰亭小区1幢1单元10102室";
      this.area = "255㎡";
      this.time = "2021.9";
    }
    if (this.$route.query.id == 15) {
      this.imgListItem = [case15_1, case15_2, case15_3, case15_4];
      this.textTitle = "兴业银行（西长安街支行）";
      this.text ="";
      this.address = "西安市长安区西长安街288号1期";
      this.area = "2000㎡";
      this.time = "2022.5";
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.nav li {
  float: left;
  line-height: 100px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
}

.main {
  width: 1860px;
  height: 600px;
  margin: 150px auto 100px;
}
.carouselClass {
  float: left;
  width: 1100px;
}
.carouselClass img {
  width: 100%;
  height: 100%;
}
.text {
  float: right;
  width: 700px;
}

.text > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 60px;
}
.text > div:nth-of-type(2) {
  font-size: 16px;
  color: #333333;
  margin-bottom: 6px;
}
.text > div:nth-of-type(4) {
  font-size: 14px;
  color: #333333;
  margin-bottom: 30px;
}
.text > div:nth-of-type(5) {
  font-size: 16px;
  color: #333333;
}
.text > div:nth-of-type(7) {
  font-size: 16px;
  color: #333333;
  margin-bottom: 30px;
}
.text > div:nth-of-type(8) {
  font-size: 16px;
  color: #333333;
}
.text > div:nth-of-type(10) {
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  letter-spacing: 3px;
}
.textClass {
  width: 28px;
  height: 2px;
  background: #666666;
  margin: 6px 0;
}
.back {
  margin-top: 20px;
  cursor: pointer;
}

.back span {
  font-size: 18px;
  color: #999999;
}
.back img {
  vertical-align: middle;
  margin-right: 10px;
}
</style>
